(function ($) {
    'use strict';

    $.extend(true, $.trumbowyg, {
        langs: {
            // jshint camelcase:false
            en: {
                textdirection: 'Text Direction',
                textdirections: {
                    'LTR':    'Left to Right',
                    'RTL':    'Right to Left'
                }
            },
            da: {
                textdirection: 'Tekstretning',
                textdirections: {
                    'LTR':    'LTR',
                    'RTL':    'RTL'
                }
            },
            fr: {
                textdirection: 'Direction du texte',
                textdirections: {
                    'LTR':    'LTR',
                    'RTL':    'RTL'
                }
            },
            nl: {
                textdirection: 'Tekstretning',
                textdirections: {
                    'LTR':    'LTR',
                    'RTL':    'RTL'
                }
            },
            tr: {
                textdirection: 'Metin yönü',
                textdirections: {
                    'LTR':    'LTR',
                    'RTL':    'RTL'
                }
            },
            zh_tw: {
                textdirection: '文字方向',
                textdirections: {
                    'LTR':    'LTR',
                    'RTL':    'RTL'
                }
            },
            pt_br: {
                textdirection: 'Direção do texto',
                textdirections: {
                    'LTR':    'LTR',
                    'RTL':    'RTL'
                }
            },
            it: {
                textdirection: 'Direzione del testo',
                textdirections: {
                    'LTR':    'LTR',
                    'RTL':    'RTL'
                }
            },
        }
    });
    // jshint camelcase:true

    // Add dropdown with font sizes
    $.extend(true, $.trumbowyg, {
        plugins: {
            textdirection: {
                init: function (trumbowyg) {
                    trumbowyg.addBtnDef('textdirection', {
                        dropdown: buildDropdown(trumbowyg),
                        ico: 'p'
                    });
                }
            }
        }
    });

    // Build the dropdown
    function buildDropdown(trumbowyg) {
        var dropdown = [];
        var sizes = ['LTR', 'RTL'];

        $.each(sizes, function(index, size) {
            trumbowyg.addBtnDef('textdirection_' + size, {
                text: '<span style="direction: ' + size + ';">' + trumbowyg.lang.textdirections[size] + '</span>',
                hasIcon: false,
                fn: function(){
                    trumbowyg.saveRange();
                    var text = trumbowyg.getRangeText();
                    if (text.replace(/\s/g, '') !== '') {
                        try {
                            var parent = getSelectionParentElement();
                            $(parent).css('direction', size);
                        } catch (e) {
                        }
                    }
                }
            });
            dropdown.push('textdirection_' + size);
        });

        return dropdown;
    }

    // Get the selection's parent
    function getSelectionParentElement() {
        var parentEl = null,
            selection;
        if (window.getSelection) {
            selection = window.getSelection();
            if (selection.rangeCount) {
                parentEl = selection.getRangeAt(0).commonAncestorContainer;
                if (parentEl.nodeType !== 1) {
                    parentEl = parentEl.parentNode;
                }
            }
        } else if ((selection = document.selection) && selection.type !== 'Control') {
            parentEl = selection.createRange().parentElement();
        }
        return parentEl;
    }
})(jQuery);
