/* ===========================================================
 * trumbowyg.colors.js v1.2
 * Colors picker plugin for Trumbowyg
 * http://alex-d.github.com/Trumbowyg
 * ===========================================================
 * Author : Alexandre Demode (Alex-D)
 *          Twitter : @AlexandreDemode
 *          Website : alex-d.fr
 */

(function ($) {
    'use strict';

    $.extend(true, $.trumbowyg, {
        langs: {
            // jshint camelcase:false
            en: {
                foreColor: 'Text color',
                backColor: 'Background color',
                foreColorRemove: 'Remove text color',
                backColorRemove: 'Remove background color'
            },
            cs: {
                foreColor: 'Barva textu',
                backColor: 'Barva pozadí'
            },
            da: {
                foreColor: 'Tekstfarve',
                backColor: 'Baggrundsfarve'
            },
            de: {
                foreColor: 'Textfarbe',
                backColor: 'Hintergrundfarbe'
            },
            et: {
                foreColor: 'Teksti värv',
                backColor: 'Taustavärv',
                foreColorRemove: 'Eemalda teksti värv',
                backColorRemove: 'Eemalda taustavärv'
            },
            fr: {
                foreColor: 'Couleur du texte',
                backColor: 'Couleur de fond',
                foreColorRemove: 'Supprimer la couleur du texte',
                backColorRemove: 'Supprimer la couleur de fond'
            },
            hu: {
                foreColor: 'Betű szín',
                backColor: 'Háttér szín',
                foreColorRemove: 'Betű szín eltávolítása',
                backColorRemove: 'Háttér szín eltávolítása'
            },
            ja: {
                foreColor: '文字色',
                backColor: '背景色'
            },
            ko: {
                foreColor: '글자색',
                backColor: '배경색',
                foreColorRemove: '글자색 지우기',
                backColorRemove: '배경색 지우기'
            },
            nl: {
                foreColor: 'Tekstkleur',
                backColor: 'Achtergrondkleur'
            },
            pt_br: {
                foreColor: 'Cor de fonte',
                backColor: 'Cor de fundo'
            },
            ru: {
                foreColor: 'Цвет текста',
                backColor: 'Цвет выделения текста'
            },
            sk: {
                foreColor: 'Farba textu',
                backColor: 'Farba pozadia'
            },
            tr: {
                foreColor: 'Yazı rengi',
                backColor: 'Arka plan rengi',
                foreColorRemove: 'Yazı rengini kaldır',
                backColorRemove: 'Arka plan rengini kaldır'
            },
            zh_cn: {
                foreColor: '文字颜色',
                backColor: '背景颜色'
            },
            zh_tw: {
                foreColor: '文字顏色',
                backColor: '背景顏色'
            },
        }
    });

    // jshint camelcase:true


    function hex(x) {
        return ('0' + parseInt(x).toString(16)).slice(-2);
    }

    function colorToHex(rgb) {
        if (rgb.search('rgb') === -1) {
            return rgb.replace('#', '');
        } else if (rgb === 'rgba(0, 0, 0, 0)') {
            return 'transparent';
        } else {
            rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d?(.\d+)))?\)$/);
            return hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
        }
    }

    function colorTagHandler(element, trumbowyg) {
        var tags = [];

        if (!element.style) {
            return tags;
        }

        // background color
        if (element.style.backgroundColor !== '') {
            var backColor = colorToHex(element.style.backgroundColor);
            if (trumbowyg.o.plugins.colors.colorList.indexOf(backColor) >= 0) {
                tags.push('backColor' + backColor);
            } else {
                tags.push('backColorFree');
            }
        }

        // text color
        var foreColor;
        if (element.style.color !== '') {
            foreColor = colorToHex(element.style.color);
        } else if (element.hasAttribute('color')) {
            foreColor = colorToHex(element.getAttribute('color'));
        }
        if (foreColor) {
            if (trumbowyg.o.plugins.colors.colorList.indexOf(foreColor) >= 0) {
                tags.push('foreColor' + foreColor);
            } else {
                tags.push('foreColorFree');
            }
        }

        return tags;
    }

    var defaultOptions = {
        colorList: ['000000','434343', '666666', '999999', 'b7b7b7', 'cccccc','d9d9d9', 'efefef', 'f3f3f3', 'ffffff', '980000', 'ff0000','ff9900', 'ffff00', '00ff00', '00ffff', '4a86e8','0000ff', '9900ff', 'ff00ff', 'e6b8af', 'f4cccc', 'fce5cd','fff2cc', 'd9ead3', 'd0e0e3', 'c9daf8', 'cfe2f3', 'd9d2e9', 'ead1dc', 'dd7e6b', 'ea9999', 'f9cb9c', 'ffe599', 'b6d7a8', 'a2c4c9', 'a4c2f4', '9fc5e8', 'b4a7d6', 'd5a6bd', 'cc4125', 'e06666', 'f6b26b', 'ffd966', '93c47d', '76a5af', '6d9eeb', '6fa8dc', '8e7cc3', 'c27ba0', 'a61c00', 'cc0000', 'e69138', 'f1c232', '6aa84f', '45818e', '3c78d8', '3d85c6', '674ea7', 'a64d79', '85200c', '990000', 'b45f06', 'bf9000', '38761d', '134f5c', '1155cc', '0b5394', '351c75', '741b47', '5b0f00', '660000', '783f04', '7f6000', '274e13', '0c343d', '1c4587', '073763', '20124d', '4c1130'],		
        foreColorList: null, // fallbacks on colorList
        backColorList: null, // fallbacks on colorList
        allowCustomForeColor: true,
        allowCustomBackColor: true,
        displayAsList: false,
    };

    // Add all colors in two dropdowns
    $.extend(true, $.trumbowyg, {
        plugins: {
            color: {
                init: function (trumbowyg) {
                    trumbowyg.o.plugins.colors = trumbowyg.o.plugins.colors || defaultOptions;
                    var dropdownClass = trumbowyg.o.plugins.colors.displayAsList ? trumbowyg.o.prefix + 'dropdown--color-list' : '';

                    var foreColorBtnDef = {
                        dropdown: buildDropdown('foreColor', trumbowyg),
                        dropdownClass: dropdownClass,
                    },
                    backColorBtnDef = {
                        dropdown: buildDropdown('backColor', trumbowyg),
                        dropdownClass: dropdownClass,
                    };

                    trumbowyg.addBtnDef('foreColor', foreColorBtnDef);
                    trumbowyg.addBtnDef('backColor', backColorBtnDef);
                },
                tagHandler: colorTagHandler
            }
        }
    });

    function buildDropdown(fn, trumbowyg) {
        var dropdown = [],
            trumbowygColorOptions = trumbowyg.o.plugins.colors,
            colorList = trumbowygColorOptions[fn + 'List'] || trumbowygColorOptions.colorList;

        $.each(colorList, function (i, color) {
            var btn = fn + color,
                btnDef = {
                    fn: fn,
                    forceCss: true,
                    hasIcon: false,
                    text: trumbowyg.lang['#' + color] || ('#' + color),
                    param: '#' + color,
                    style: 'background-color: #' + color + ';'
                };

            if (trumbowygColorOptions.displayAsList && fn === 'foreColor') {
                btnDef.style = 'color: #' + color + ' !important;';
            }

            trumbowyg.addBtnDef(btn, btnDef);
            dropdown.push(btn);
        });

        // Remove color
        var removeColorButtonName = fn + 'Remove21',
            removeColorBtnDef = {
                fn: 'removeFormat',
                hasIcon: false,
				text:'',
                param: fn,
                style: 'background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQIW2NkQAAfEJMRmwBYhoGBYQtMBYoAADziAp0jtJTgAAAAAElFTkSuQmCC);'
            };

        if (trumbowygColorOptions.displayAsList) {
            removeColorBtnDef.style = '';
        }

        trumbowyg.addBtnDef(removeColorButtonName, removeColorBtnDef);
        dropdown.push(removeColorButtonName);

        // Custom color
        if (trumbowygColorOptions['allowCustom' + fn.charAt(0).toUpperCase() + fn.substr(1)]) {
            // add free color btn
            var freeColorButtonName = fn + 'Free',
                freeColorBtnDef = {
                    fn: function () {
                        trumbowyg.openModalInsert(trumbowyg.lang[fn],
                            {
                                color: {
                                    label: fn,
                                    forceCss: true,
                                    type: 'color',
                                    value: '#FFFFFF'
                                }
                            },
                            // callback
                            function (values) {
                                trumbowyg.execCmd(fn, values.color);
                                return true;
                            }
                        );
                    },
                    hasIcon: false,
                    text: '#',
                    // style adjust for displaying the text
                    style: 'text-indent: 0; line-height: 20px; padding: 0 5px;'
                };

         /*   trumbowyg.addBtnDef(freeColorButtonName, freeColorBtnDef);
            dropdown.push(freeColorButtonName);*/
        }

        return dropdown;
    }
})(jQuery);
